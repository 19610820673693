#hdr{
    .hdrBottom{
        background: #fff;
        a{
            color: #A82F26;
            font-weight: 700;
        }
        .hasChildren > a::after{
            border-color: #A82F26;
        }
        .gNav .downMenu ul{
            background: #F9F9F9!important;
            a{
                color: #333333;
                font-weight: 500;
                font-family: $font_family_en;
            }
        }
    }

    @media (max-width: 991px){
        .hdrInfo{
            text-align: center;
            font-size: 10px;
        }
    }
}
#hdr .searchForm .searchForm__submit, #drawer .searchForm .searchForm__submit{
    padding: 7px 15px;
    border-radius: 0 100px 100px 0;
    background:#A82F26;
    i.material-icons{
        color:#fff;
    }
}
.drawer__nav li a.drawerIcon .drawerIcon__img img{
    border-radius: 50%;
}
h2.h2A{
    text-align: left;
    span{
        small{
            font-weight: 500;
            font-size: 16px;
            line-height: 48px;
            text-align: left;
            width: 186px;
            border-bottom: 1px solid #A82F26;
            margin-bottom: 20px;
            @media (max-width: 767px){
                text-align: center;
                margin: 0 auto 10px;
            }
        }
    }
    .sub_title{
        display: block;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 1px;
        color:#6D6D6D;
        @media (max-width: 767px){
            font-size: 12px;
            line-height: 20px;
            margin-top: 10px;
        }
    }
    @media (max-width: 767px){
        text-align: center;
    }
}
.mainSliderSec{
    .slick-dotted{
        &.slick-slider{
            margin-bottom: 0;
        }
        .slick-dots{
            bottom: 30px;
        
        }
    }
}
.newsList li{
    border:none;
    h3{
        font-weight: 500;
    }
}
@media (max-width: 767px){
    .sp_p0{
        .container{
            padding: 0;
        }
    }
}
.conceptD2{

    .conceptD__img img{
        border-radius: 30px 0px 0px 0px;
    }
    .conceptD__text{
        border-radius: 0px 0px 30px 0px;
        box-shadow: 0px 4px 4px 0px #00000026;
        text-align: left;
        @media (max-width: 767px){
            background: #fff;
            color: #333;
            text-align: center;
            margin-bottom: 10px;
        }
    }
}
.button--typeA{
    font-weight: 500;
    @media (max-width: 767px){
        font-size: 16px;
    }
}
.itemArrange--typeD_row {
    justify-content: space-between;
    @media (max-width: 767px){
        gap: 20px;
    }
    .itemArrange__img{
        flex: 0 0 49%;
        max-width: 49%;
    }
    .itemArrange__text {
        padding: 0;
        @media (max-width: 767px){
            padding: 0 15px;
            text-align: center;
        }
        p{
            margin-bottom: 30px;
            line-height: 28px;
        }
        .h2C{
            font-size: 30px;
            text-align: left;
            font-weight: 700;
            line-height: 42px;
            margin-bottom: 30px;
            @media (max-width: 767px){
                text-align: center;
                font-size: 26px;
                line-height: 36px;
            }
        }
    }
}
.sec-choose{
    p{
        font-size: 18px;
        line-height: 36px;
        font-weight: 500;
        color: #A82F26;
        margin-bottom: 30px;
    }
    a.button--typeB{
        border: 1px solid #dfdfdf;
        color:#333333;
        max-width: 350px;
        padding: 15px 30px;
        font-size: 20px;
    }
    @media (max-width: 767px){
        .row{
            justify-content: center;
        }
        .container > .row{
            gap: 30px;
        }
        p{
            font-size: 14px;
            line-height: 28px;
            text-align: center;
            margin-bottom: 0;
        }
    }
}
@media (max-width: 767px){
    .sec-voice{
        img{
            max-width: 100px;
        }
    }
    .faqWrap dl{
        padding: 15px;
    }
    #favoliteSection li:nth-child(n + 3){
        display: none;
    }
}
.sec-gift{
    a{
        max-width: 700px;
        display: block;
        margin: 0 auto;
    }
}
.sec-omoi{
    img{
        margin-bottom: 30px;
        width: 230px;
        @media (max-width: 767px){
            width: 160px;
        }
    }
    p{
        font-weight: 500;
        line-height: 32px;
    }
    
}
section .itemList{
    .itemList__unit{
        img{
            border-radius: 30px;
        }
    }
}
.sec-sns{
    margin-bottom: 50px;
    h2{
        color: #A82F26;
        font-size: 30px;
        line-height: 48px;
        font-weight: 700;
        margin-bottom: 0;
        @media (max-width: 767px){
            font-size: 26px;
        }
    }
    p{
        font-weight: 500;
        line-height: 48px;
        font-size: 16px;
        text-align: center;
    }
    .snsNav{
        justify-content: center;
        gap: 50px;
        @media (max-width: 767px){
            gap: 20px;
        }
        i{
            font-size: 50px;
            @media (max-width: 767px){
                font-size: 47px;
            }
        }
    }
}
#ftr{
    padding: 20px 0;
}